import React from 'react';
import { stripHtmlTags, truncateSentence } from 'src/helpers';
import styled from 'styled-components';

export * from './config';

export interface Props {
  text: string,
  rotation?: number,
  transform?: React.CSSProperties['transform'],
  transformOrigin?: React.CSSProperties['transformOrigin'],
  color?: React.CSSProperties['color'],
  strokeWidth?: React.CSSProperties['width'],
  fontSize?: React.CSSProperties['fontSize'],
  opacity?: React.CSSProperties['opacity'],
  width?: React.CSSProperties['width'],
  maxWidth?: React.CSSProperties['maxWidth'],
  top?: React.CSSProperties['top'],
  right?: React.CSSProperties['right'],
  bottom?: React.CSSProperties['bottom'],
  left?: React.CSSProperties['left'],
  className?: string,
  characterLimit?: number,
}

const TypographyBackround2:React.FC<Props> = ({
  text,
  rotation = -25,
  transform,
  transformOrigin,
  color,
  strokeWidth = '0.7px',
  fontSize = '3rem',
  opacity = 0.5,
  width,
  maxWidth = 'none',
  top,
  right,
  bottom,
  left,
  className,
  characterLimit = 400,
}) => {
  const textCapped = truncateSentence(text, characterLimit);
  const textCappedNoTags = stripHtmlTags(textCapped);

  return (
    <TypographyBackround2Outer className={className}>
      <Inner
        className="inner"
        $rotation={rotation}
        $transform={transform}
        $transformOrigin={transformOrigin}
        $color={color}
        $strokeWidth={strokeWidth}
        $fontSize={fontSize}
        $opacity={opacity}
        $width={width}
        $maxWidth={maxWidth}
        $top={top}
        $right={right}
        $bottom={bottom}
        $left={left}
        dangerouslySetInnerHTML={{ __html: textCappedNoTags }}
      />
    </TypographyBackround2Outer>
  );
};
export default TypographyBackround2;

const TypographyBackround2Outer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
`;

interface InnerProps {
  $rotation: number,
  $transform: React.CSSProperties['transform'],
  $transformOrigin: React.CSSProperties['transformOrigin'],
  $color: React.CSSProperties['color'],
  $strokeWidth: React.CSSProperties['width'],
  $opacity: React.CSSProperties['opacity'],
  $width: React.CSSProperties['width'],
  $maxWidth: React.CSSProperties['maxWidth'],
  $fontSize: React.CSSProperties['fontSize'],
  $top: React.CSSProperties['top'],
  $right: React.CSSProperties['right'],
  $bottom: React.CSSProperties['bottom'],
  $left: React.CSSProperties['left'],
}
const Inner = styled.div<InnerProps>`
  position: absolute;
  top: ${(p) => p.$top};
  right: ${(p) => p.$right};
  bottom: ${(p) => p.$bottom};
  left: ${(p) => p.$left};
  width: ${(p) => p.$width};
  max-width: ${(p) => p.$maxWidth};
  font-size: ${(p) => p.$fontSize};
  font-weight: bold;
  line-height: 1em;
  transform: ${(p) => p.$transform && p.$transform} rotate(${(p) => p.$rotation}deg);
  transform-origin: ${(p) => p.$transformOrigin};
  opacity: 0.1;
  color: ${(p) => p.$color || p.theme.palette.primary};
  -webkit-text-stroke-color: ${(p) => p.$color || p.theme.palette.primary};
  -webkit-stroke-width: 0;
  stroke-width: 0;
  .dark-bg & {
    color: ${(p) => p.$color || p.theme.palette.secondary};
    -webkit-text-stroke-color: ${(p) => p.$color || p.theme.palette.secondary};
  }
  @supports(-webkit-text-stroke: 1px white) or (text-stroke: 1px white) {
    opacity: ${(p) => p.$opacity};
    color: transparent !important;
    -webkit-text-stroke-width: ${(p) => p.$strokeWidth};
    text-stroke-width: ${(p) => p.$strokeWidth};
  }
`;
