import React from 'react';
import Container from 'src/components/Container';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Block from 'components/Block';
import Spacer from 'components/Spacer';
import NavHeader from 'src/components/NavHeader';
import TypographyBackround2, { standardPageHeader } from 'components/TypographyBackround2';
import useDynamicTitleVariant from 'src/hooks/useDynamicTitleVariant';

export interface Props {
  h1?: string,
  text?: string,
  category?: string,
  hideNavHeader?: boolean,
  backgroundText?: string,
}

const PageHeader:React.FC<Props> = ({
  h1,
  text,
  category,
  hideNavHeader = false,
  backgroundText,
}) => {
  const dynamicTitleVariant = useDynamicTitleVariant(h1 || '');
  return (
    <PageHeaderOuter>
      <Block darkBg type="noPadding">
        {backgroundText && (
          <TypographyBackround2
            text={backgroundText}
            {...standardPageHeader}
          />
        )}
        {!hideNavHeader && <NavHeader />}
        <Container yPadding={0}>
          {(h1 || text || category) && (
            <Spacer py={5} multiplier={25}>
              {category && <Typography variant="h3" as="p" color="white">{category}</Typography>}
              {h1 && (
                <Spacer
                  multiplier={5}
                  mt={dynamicTitleVariant === 'pageTitle' ? 0 : 2}
                  ml={dynamicTitleVariant === 'pageTitle' ? -0.5 : 0}
                >
                  <Typography maxWidth="25em" variant={dynamicTitleVariant} as="h1">{h1}</Typography>
                </Spacer>
              )}
              {text && (
                <Spacer mt={1}>
                  <Typography variant="body">{text}</Typography>
                </Spacer>
              )}
            </Spacer>
          )}
        </Container>
      </Block>
    </PageHeaderOuter>
  );
};
export default PageHeader;

const PageHeaderOuter = styled.div``;
