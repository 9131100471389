import React from 'react';
import SEO from 'lib/components/Seo';
import Block from 'src/components/Block';
import PageHeader from 'components/PageHeader';
import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { Link } from 'gatsby';
import Spacer from 'src/components/Spacer';

interface Props {}

const TestPage:React.FC<Props> = () => {
  return (
    <TestPageOuter>
      <SEO
        title="404"
        description="404"
      />
      <PageHeader h1="Not Found" />
      <Block contain title="404">
        <Typography variant="h5" as="p">Oh dear, no page found here 😬!</Typography>
        <Spacer mb={1} />
        <Typography>Maybe you can find what your&apos;e looking for in the above navigation, or from the <Link to="/">homepage</Link> and try again.</Typography>
      </Block>
    </TestPageOuter>
  );
};
export default TestPage;

const TestPageOuter = styled.div``;
